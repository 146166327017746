import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "tab", "content" ]
  static classes = [ "active", "inactive" ]

  initialize() {
    this.index = 0
    this.showCurrentSlide()
  }

  select({ params }) {
    this.index = parseInt(params.index)
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    this.contentTargets.forEach((element, index) => {
      const tab = this.tabTargets[index]

      if (index !== this.index) {
        element.hidden = true
        tab.classList.add(...this.inactiveClasses)
        tab.classList.remove(...this.activeClasses)
      } else {
        element.hidden = false
        tab.classList.add(...this.activeClasses)
        tab.classList.remove(...this.inactiveClasses)
      }
    })
  }
}
