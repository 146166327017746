import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "toggle" ]
  static targets = [ "container", "rotate" ]

  toggle() {
    this.containerTarget.classList.toggle(this.toggleClass)

    if (this.hasRotateTarget) {
      this.rotateTarget.classList.toggle("rotate-45")
    }
  }
}
